/* eslint-disable no-unused-vars */
import ImmeubleService from "../services/immeuble.service.js";

export const immeuble = {
    namespaced: true,
    state: {
        immeublesList: null,
        oneImmeuble: null,
        oneImmeubleMini: null,
        oneImmeubleResidents: null,
        oneImmeubleContrats: null,
        oneImmeubleHistorique: null,
        checkImmeuble:null,
        nearImmeuble: null,
        mailsImmeuble: null,
        image: null,
        attr: null,
        file: null,
        exportImmeubles: null,
    },
    getters: {
        immeublesList: state => state.immeublesList,
        oneImmeuble: state => state.oneImmeuble,
        oneImmeubleMini: state => state.oneImmeubleMini,
        oneImmeubleResidents: state => state.oneImmeubleResidents,
        oneImmeubleContrats: state => state.oneImmeubleContrats,
        oneImmeubleHistorique: state => state.oneImmeubleHistorique,
        checkImmeuble: state => state.checkImmeuble,
        nearImmeuble: state => state.nearImmeuble,
        mailsImmeuble: state => state.mailsImmeuble,
        exportImmeubles: state => state.exportImmeubles,
        attr: state => state.attr,
        file: state => state.file,
        image: state => state.image,
    },
    actions: {
        async immeublesList({ commit }, params) {
            console.log("action")
            const data = await ImmeubleService.getListImmeubles(params);
            console.log(data);
            commit('IMMEUBLES_LIST', data);
        },
        async getImmeuble({ commit }, itemId) {
            const data = await ImmeubleService.getImmeuble(itemId);
            commit('GET_IMMEUBLE', data);
        },
        async editImmeubleCollab({ commit }, item){
            await ImmeubleService.editImmeubleCollab(item);
            commit('','')
        },
        async nearImmeuble({ commit }, itemId) {
            const data = await ImmeubleService.nearImmeuble(itemId);
            commit('GET_NEAR_IMMEUBLE', data);
        },
        async mailsImmeuble({ commit }, itemId) {
            const data = await ImmeubleService.mailsImmeuble(itemId);
            commit('GET_MAIL_IMMEUBLE', data);
        },
        async getImmeubleResidents({ commit }, itemId) {
            const data = await ImmeubleService.getImmeubleResidents(itemId);
            commit('GET_IMMEUBLE_RESIDENTS', data);
        },
        async getImmeubleContrats({ commit }, itemId) {
            const data = await ImmeubleService.getImmeubleContrats(itemId);
            commit('GET_IMMEUBLE_CONTRATS', data);
        },
        async getImmeubleHistorique({ commit }, itemId) {
            const data = await ImmeubleService.getImmeubleHistorique(itemId);
            commit('GET_IMMEUBLE_HISTORIQUE', data);
        },
        async getImmeubleMini({ commit }, itemId) {
            const data = await ImmeubleService.getImmeubleMini(itemId);
            commit('GET_IMMEUBLE_MINI', data);
        },
        async r_checkImmeuble({ commit }, item) {
            const data = await ImmeubleService.r_checkImmeuble(item);
            commit('CHECK_IMMEUBLE', data);
        },
        async addImmeuble({ commit }, item) {
            const data = await ImmeubleService.createImmeuble(item);
            commit('GET_IMMEUBLE', data);
        },
        async addAttributeImmeuble({ commit }, item) {
            console.log(item);
            const data = await ImmeubleService.createAttributeImmeuble(item);
            commit('GET_ATTRIBUTE_IMMEUBLE', data);
        },
        async updateAttributeImmeuble({ commit }, item) {
            console.log(item);
            const data = await ImmeubleService.updateAttributeImmeuble(item);
            commit('GET_ATTRIBUTE_IMMEUBLE', data);
        },

                async exportImmeubles({ commit }, params) {
                    console.log("action")
                    const data = await ImmeubleService.exportImmeubles(params);
                    console.log(data);
                    commit('EXPORT_IMMEUBLES', data);
                },
        async getFile({ commit }, item) {
            console.log(item);
            const data = await ImmeubleService.getFile(item);
            commit('GET_FILE', data);
        },
        async removeAttributeImmeuble({ commit }, item) {
            console.log(item);
            const data = await ImmeubleService.removeAttributeImmeuble(item);
            commit('GET_ATTRIBUTE_IMMEUBLE', data);
        },
        async editImmeuble({ commit }, item) {
            await ImmeubleService.editImmeuble(item);
        },
        async deleteImmeuble({ commit }, itemId) {
            await ImmeubleService.deleteImmeuble(itemId);
        },
        async uploadImageItem({ commit }, item) {
            const data = await ImmeubleService.uploadImageImmeuble(item);
            commit('UPLOAD_IMAGE_IMMEUBLE', data);
        },
    },
    mutations: {
        IMMEUBLES_LIST(state, data) {
            state.immeublesList = data;
        },
        GET_IMMEUBLE(state, data) {
            state.oneImmeuble = data;
        },
        GET_IMMEUBLE_MINI(state, data) {
            state.oneImmeubleMini = data;
        },
        CHECK_IMMEUBLE(state, data) {
            state.checkImmeuble = data;
        },
        GET_IMMEUBLE_RESIDENTS(state, data) {
            state.oneImmeubleResidents = data;
        },
        GET_IMMEUBLE_CONTRATS(state, data) {
            state.oneImmeubleContrats = data;
        },
        GET_IMMEUBLE_HISTORIQUE(state, data) {
            state.oneImmeubleHistorique = data;
        },
        EXPORT_IMMEUBLES(state, data) {
            state.exportImmeubles = data;
        },


        GET_NEAR_IMMEUBLE(state, data) {
            state.nearImmeuble = data;
        },
        GET_MAIL_IMMEUBLE(state, data) {
            state.mailsImmeuble = data;
        },


        GET_ATTRIBUTE_IMMEUBLE(state, data) {
            state.attr = data;
        },
        GET_FILE(state, data) {
            state.file = data;
        },
        UPLOAD_IMAGE_IMMEUBLE(state, data) {
            state.image = data;
        },

  }
}
