import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";
import qs from 'qs';

const API_URL = process.env.VUE_APP_API_BASE_URL;

const jsona = new Jsona();

async function getListImmeubles(params){
  const response =  await axios.get(`${API_URL}/immeubles`, {
    params: params,
    paramsSerializer: params => {
      return qs.stringify(params, { encode: false });
    },
    headers: authHeader()
  });

  return {data: response.data.data, meta: response.data.meta};
}

async function getImmeuble(ImmeubleId){
  const response =  await axios.get(`${API_URL}/immeubles/${ImmeubleId}?include=category,tags`, {headers: authHeader()});
  return response.data;
}

async function nearImmeuble(ImmeubleId){
  const response =  await axios.get(`${API_URL}/immeubles/${ImmeubleId}/near?include=category,tags`, {headers: authHeader()});
  return response.data;
}

async function mailsImmeuble(ImmeubleId){
  const response =  await axios.get(`${API_URL}/immeubles/${ImmeubleId}/mails`, {headers: authHeader()});
  return response.data;
}

async function getImmeubleMini(ImmeubleId){
  const response =  await axios.get(`${API_URL}/immeubles/${ImmeubleId}/mini`, {headers: authHeader()});
  return response.data;
}

async function getImmeubleResidents(ImmeubleId){
    const response =  await axios.get(`${API_URL}/immeubles/${ImmeubleId}/residents`, {headers: authHeader()});
    return response.data;
  }

  async function getImmeubleContrats(ImmeubleId){
    const response =  await axios.get(`${API_URL}/immeubles/${ImmeubleId}/contrats`, {headers: authHeader()});
    return response.data;
  }

  async function exportImmeubles(param){

    const response =  await axios.get(`${API_URL}/export/immeubles`, {params:param, headers: authHeader()});
    // get as attachment
    // add
    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv', encoding: 'UTF-8' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'export_immeubles.csv');
    document.body.appendChild(link);
    link.click();
    link.remove();
    return response.data;
  }

  async function getImmeubleHistorique(ImmeubleId){
    const response =  await axios.get(`${API_URL}/immeubles/${ImmeubleId}/historique`, {headers: authHeader()});
    return response.data;
  }

async function editImmeuble(Immeuble){

  const payload = jsona.serialize({
    stuff: Immeuble,
    includeNames: []
  });

  const response =  await axios.patch(`${API_URL}/immeubles/${Immeuble.id}?include=category,tags`, payload, {headers: authHeader()});
  return jsona.deserialize(response.data);
}


async function editImmeubleCollab(Immeuble){

    const response =  await axios.patch(`${API_URL}/immeubles/${Immeuble.id}/collaborateur`, Immeuble, {headers: authHeader()});
    return response.data;
  }


async function  r_checkImmeuble(Immeuble){


  const response =  await axios.post(`${API_URL}/r/immeubles/check`, Immeuble, {headers: authHeader()});
  return response.data;
}


async function createImmeuble(Immeuble){

  const payload = jsona.serialize({
    stuff: Immeuble,
    includeNames: []
  });

  const response =  await axios.post(`${API_URL}/immeubles?include=category,tags`, payload, {headers: authHeader()});
  return jsona.deserialize(response.data);
}

async function createAttributeImmeuble(item){

  const formData = new FormData();

  Object.entries(item).forEach(([key, value]) => {
    if(key !== "files"){
        if(value !== undefined){
            formData.append(key, JSON.stringify(value));
        }
    }

  });
  if(item.files){

      item.files.forEach((file, i) => {
        formData.append("files[]", file, file.name || "filename" + i + ".jpg");
    });

  }

  const response =  await axios.post(`${API_URL}/immeubles/${item.id}/attr`, formData, {headers: authHeader()});
  return response.data;
}

 function updateAttributeImmeuble(item){
  const formData = new FormData();

  Object.entries(item).forEach(([key, value]) => {
    if(key !== "files"){
        if(value !== undefined){
      formData.append(key, JSON.stringify(value));}
    }

  });
  if(item.files){

      item.files.forEach((file, i) => {
        formData.append("files[]", file, file.name || "filename" + i + ".jpg");
    });

  }


  return axios
    .post(`${API_URL}/immeubles/${item.immeuble_id}/attr/${item.id}`, formData, {headers: authHeader()})
    .then(response => {
      return response.data;
    });


}


function getFile(item) {
  const options = {headers: authHeader()}
  return axios
    .get(`${API_URL}/immeubles/${item.immeuble_id}/attr/${item.id}/files/${item.name}`, options)
    .then(response => {
      let att = response.data;
      return att;
    });
}

function removeAttributeImmeuble(item) {
  const formData = new FormData();

  Object.entries(item).forEach(([key, value]) => {
    if(key !== "files"){

      formData.append(key, JSON.stringify(value));
    }

  });
  const options = {headers: authHeader()}

  return axios
    .delete(`${API_URL}/immeubles/${item.immeuble_id}/attr/${item.id}`, formData, options)
    .then(response => {
      return response.data;
    });
}

async function deleteImmeuble(ImmeubleId){
  await axios.delete(`${API_URL}/immeubles/${ImmeubleId}`, {headers: authHeader()});
}

async function uploadImageImmeuble(Immeuble) {
    const payload = new FormData();
    payload.append("attachment", Immeuble.image);

    const response = await axios.post(`${API_URL}/uploads/immeubles/${Immeuble.id}/image`, payload, { headers: authHeader() });
    return response.data.url;

  }

export default {
  r_checkImmeuble,editImmeubleCollab,
  getListImmeubles,
    getImmeuble,nearImmeuble,mailsImmeuble,getImmeubleResidents,getImmeubleContrats,getImmeubleHistorique,
    getImmeubleMini,
    editImmeuble,
    createImmeuble,
    createAttributeImmeuble,updateAttributeImmeuble,getFile,removeAttributeImmeuble,
    deleteImmeuble,
    uploadImageImmeuble,
    exportImmeubles
};
